import React from 'react';
import clsx from 'clsx';
import ResponsiveImageRender from '../../../ResponsiveImageRender';
import useStyles from '../styles';

interface ProductImageProps {
  imageUrl: string;
  description: string;
  onClick?: (e?: React.SyntheticEvent) => void;
  classes?: { imageContainer?: string; image?: string };
}

const ProductImage = (props: ProductImageProps): JSX.Element => {
  const classes = useStyles();
  const {
    imageUrl, description, onClick, classes: overrideClasses
  } = props;

  return (
    <div className={clsx(classes.imageContainer, overrideClasses?.imageContainer)}>
      <ResponsiveImageRender
        title=""
        testId=""
        className={clsx(classes.image, overrideClasses?.image)}
        mobile={imageUrl || '/images/fallback-product-image.jpeg'}
        desktop={imageUrl || '/images/fallback-product-image.jpeg'}
        altText={description}
        onClick={onClick}
        ariaHidden
      />
    </div>
  );
};

export default ProductImage;
